<script>
/**
 * Summary Component
 */
export default {
  data () {
    return {
      apps: [{
        id: 1,
        name: 'Android & TV',
        url: 'https://play.google.com/store/apps/details?id=co.vrsteps.app',
        newTab: true,
        badge: {
          color: 'badge-soft-success',
          text: 'Available'
        }
      }, {
        id: 2,
        name: 'iOS & TV',
        url: '',
        newTab: true,
        badge: {
          color: 'badge-soft-warning',
          text: 'Coming Soon'
        }
      }, {
        id: 3,
        name: 'Windows',
        url: '',
        newTab: true,
        badge: {
          color: 'badge-soft-warning',
          text: 'Coming Soon'
        }
      }, {
        id: 4,
        name: 'Web Browser',
        url: 'https://www.vrsteps.co/web/',
        newTab: false,
        badge: {
          color: 'badge-soft-success',
          text: 'Available'
        }
      }],
    }
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  mounted () {

  },
  computed: {
    statData () {
      return [
        {
          title: 'User type',
          icon: 'ri-briefcase-4-line',
          value: this.$store.state.user.type,
        },
        {
          title: 'Number of Patients',
          icon: 'dripicons-user-group',
          value: `${this.$store.state.monitor.patients.length} / ${this.isAdmin ? '∞' : 5}`,
        },
        {
          title: 'New Messages',
          icon: ' dripicons-mail',
          value: '0',
        }
      ]
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="media row">
        <div class="col-lg-4">
          <div class="d-flex flex-lg-row">
            <img
                src="@/assets/images/logo-sm-light.png"
                alt
                class="avatar-xs"
            />
            <h5 class="ml-2 mb-0" style="line-height: 32px">v78 April 2022</h5>
          </div>
        </div>
        <div class="col-lg-2 text-right" :key="a.id" v-for="a in apps">
          <a :href="a.url" :target="{'_blank': a.newTab}" rel="nofollow noopener">
            <h5>{{ a.name }} <span :class="['badge',a.badge.color]">{{ a.badge.text }}</span></h5>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0 text-capitalize">{{ data.value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
        <!--        <div class="card-body border-top py-3">-->
        <!--          <div class="text-truncate">-->
        <!--            <span class="badge badge-soft-success font-size-11">-->
        <!--              <i class="mdi mdi-menu-up"></i>-->
        <!--              {{ data.subvalue }}-->
        <!--            </span>-->
        <!--            <span class="text-muted ml-2">From previous period</span>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
