<script>
import { getApi } from '@/api'
import PageSubtitle from '@/components/page-subtitle'

/**
 * Patients Overview
 */
export default {
  components: {
    PageSubtitle
  },
  data () {
    return {
      selectedPatient: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: 'last_name',
      sortDesc: false,
      fields: [
        {
          key: 'last_name',
          sortable: true
        },
        {
          key: 'first_name',
          sortable: true
        },
        {
          key: 'email',
          sortable: true
        },
        {
          key: 'type',
          sortable: true
        },
        {
          key: 'country',
          sortable: true
        },
        {
          key: 'activation_token',
          sortable: true
        },
        {
          key: 'gender',
          sortable: true
        }
      ],
      imgProfile: require('@/assets/images/logo-sm-dark.png'),
      activityLog: [],
      activityTime: 'today',
      tableData: this.$store.state.monitor.users,
      title: 'Advanced Table',
      items: [
        {
          text: 'Tables',
          href: '/'
        },
        {
          text: 'Advanced',
          active: true
        }
      ],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows () {
      return this.tableData.length
    }
  },
  methods: {
    ...getApi(),
    // eslint-disable-next-line no-unused-vars
    selectPatient (idx) {

    },
    /**
     * Search the table data with search input
     */
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  mounted () {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.selectPatient(0)
  }
}
</script>

<template>
  <div>
    <div class="">
      <div class="row">
        <div class="col-6">
          <PageSubtitle :title="'Users'"/>
        </div>
        <div class="col-6">
          <router-link :to="`/account/create`" class="pointer text-center">
            <h6 class="mb-0"><i class="mdi mdi-plus mr-1"></i>New member</h6></router-link>
        </div>
      </div>
    </div>
    <div class="card mb-2">
      <div class="card-body">
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
              :items="tableData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
          ></b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
