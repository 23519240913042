<script>
import { getApi } from '@/api'
import PageSubtitle from '@/components/page-subtitle'

/**
 * Patients Overview
 */
export default {
  components: {
    PageSubtitle
  },

  data () {
    return {
      selectedPatient: 0,
      imgProfile: require('@/assets/images/logo-sm-dark.png'),
      activityLog: [
        {
          icon: 'ri-edit-2-fill',
          date: '28 Apr, 2020 ',
          time: '12:07 am',
          title: 'Responded to need “Volunteer Activities”'
        },
        {
          icon: 'ri-user-2-fill',
          date: '21 Apr, 2020 ',
          time: '08:01 pm',
          title: 'Added an interest “Volunteer Activities”'
        },
        {
          icon: 'ri-bar-chart-fill',
          date: '17 Apr, 2020 ',
          time: '05:10 pm',
          title: 'Responded to need “In-Kind Opportunity”'
        },
        {
          icon: 'ri-calendar-2-fill',
          date: '07 Apr, 2020',
          time: '12:47 pm',
          title: 'Created need “Volunteer Activities”'
        },
      ],
      activityTime: 'today'
    }
  },
  computed: {
    patient () {
      return this.$store.state.monitor.patients[this.selectedPatient]
    },
    chartLabels () {
      switch (this.activityTime) {
        case 'today':
          return Array.from(Array(24).keys()).map(h => `${h}:00`)
        case 'weekly':
          return [
            'Sun',
            'Mon',
            'Tue',
            'Wed',
            'Thu',
            'Fri',
            'Sat',
          ]
        default:
          //todo month days back
          return Array.from(Array(31).keys()).map(d => `${d}/04`)
      }
    },

    chartData () {
      return [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16]
    },
    chartSeries () {
      return [
        {
          name: '2020',
          type: 'column',
          data: this.chartData
        },
        // {
        //   name: '2019',
        //   type: 'line',
        //   data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16]
        // }
      ]
    },
    chartOptions () {
      return {
        chart: {
          height: 280,
          type: 'line',
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [0, 3],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%'
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        colors: ['#5664d2', '#1cbb8c'],
        labels: this.chartLabels
      }
    },
  },
  methods: {
    ...getApi(),
    selectPatient (idx) {
      if (this.selectedPatient === idx) return

      //erase prev data
      this.activityLog = []

      this.selectedPatient = idx

      let userId = this.$store.state.user.id
      let accessToken = this.$store.state.user.access_token

      this.getPatientLog(userId, accessToken, this.patient.id).then(response => response.json()).then(
          (results) => {

            if (results.status) {
              this.activityLog = results.patient_log.map((log) => {
                log.icon = 'ri-edit-2-fill'
                let d = log.datetime.split(' ')
                log.date = d[0]
                log.time = d[1]
                return log
              })
            }
          }
      )

    }
  },
  mounted () {
    this.selectPatient(0)
  }
}
</script>

<template>
  <div>
    <PageSubtitle :title="'Your patients'"/>
    <div class="card mb-2">
      <div class="card-body">
        <div class="row">
          <div :key="'p-1'+k" class="col-xl-1 col-md-2 col-sm-3 col-6  mb-2 mb-xl-0"
               v-for="(p,k) in $store.state.monitor.patients">
            <div class="pointer text-center" @click="()=> selectPatient(k)"
                 :class="{unselected: k!==selectedPatient}">
              <img :src="p.image? p.image : imgProfile" :alt="p.name" class="card-img rounded-circle shadow mb-2">
              <h6 class="mb-0">{{ p.name }}</h6>
            </div>
          </div>
          <div class="col-xl-1 col-md-2 col-sm-3 col-6 mb-2 mb-xl-0">
            <router-link :to="`/account/create`" class="pointer text-center">
              <img alt="VRsteps New Member" src="@/assets/images/vrsteps-logo-4.png"
                   class="card-img rounded-circle shadow mb-2">
              <h6 class="mb-0"><i class="mdi mdi-plus mr-1"></i>New member</h6></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="$store.state.monitor.patients.length">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h2 class="mb-4">{{ patient.name }}</h2>
          </div>
          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-vertical"></i>
                  </template>
                  <!-- item-->
                  <b-dropdown-item>Export Report</b-dropdown-item>
                  <!-- item-->
                  <!--                  <b-dropdown-item>Profit</b-dropdown-item>-->
                  <!--                  &lt;!&ndash; item&ndash;&gt;-->
                  <!--                  <b-dropdown-item>Action</b-dropdown-item>-->
                </b-dropdown>

                <h4 class="card-title mb-4">Patient Activity Log</h4>

                <div data-simplebar style="max-height: 330px;">
                  <ul class="list-unstyled activity-wid">
                    <li class="activity-list" v-for="(data, index) in activityLog" :key="index">
                      <div class="activity-icon avatar-xs">
              <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                <i :class="`${data.icon}`"></i>
              </span>
                      </div>
                      <div>
                        <div>
                          <h5 class="font-size-13">
                            {{ data.date }}
                            <small>{{ data.time }}</small>
                          </h5>
                        </div>
                        <div>
                          <p class="text-muted mb-0">{{ data.title }}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="card">
              <div class="card-body">
                <div class="float-right d-none d-md-inline-block">
                  <div class="btn-group mb-2">
                    <button type="button" @click="activityTime='today'" class="btn btn-sm btn-light"
                            :class="{active:activityTime==='today'}">Today
                    </button>
                    <button type="button" @click="activityTime='weekly'" class="btn btn-sm btn-light"
                            :class="{active:activityTime==='weekly'}">Weekly
                    </button>
                    <button type="button" @click="activityTime='monthly'" class="btn btn-sm btn-light"
                            :class="{active:activityTime==='monthly'}">Monthly
                    </button>
                  </div>
                </div>
                <h4 class="card-title mb-4">Patient Activity Analytics</h4>
                <div>
                  <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
                  <apexchart
                      class="apex-charts"
                      height="280"
                      dir="ltr"
                      :series="chartSeries"
                      :options="chartOptions"
                  ></apexchart>
                </div>
              </div>

              <!--              <div class="card-body border-top text-center">-->
              <!--                <div class="row">-->
              <!--                  <div class="col-sm-4">-->
              <!--                    <div class="d-inline-flex">-->
              <!--                      <h5 class="mr-2">$12,253</h5>-->
              <!--                      <div class="text-success">-->
              <!--                        <i class="mdi mdi-menu-up font-size-14"></i>2.2 %-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                    <p class="text-muted text-truncate mb-0">This month</p>-->
              <!--                  </div>-->
              <!--                  <div class="col-sm-4">-->
              <!--                    <div class="mt-4 mt-sm-0">-->
              <!--                      <p class="mb-2 text-muted text-truncate">-->
              <!--                        <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> This Year :-->
              <!--                      </p>-->
              <!--                      <div class="d-inline-flex">-->
              <!--                        <h5 class="mb-0 mr-2">$ 34,254</h5>-->
              <!--                        <div class="text-success">-->
              <!--                          <i class="mdi mdi-menu-up font-size-14"></i>2.1 %-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="col-sm-4">-->
              <!--                    <div class="mt-4 mt-sm-0">-->
              <!--                      <p class="mb-2 text-muted text-truncate">-->
              <!--                        <i class="mdi mdi-circle text-success font-size-10 mr-1"></i> Previous Year :-->
              <!--                      </p>-->
              <!--                      <div class="d-inline-flex">-->
              <!--                        <h5 class="mb-0">$ 32,695</h5>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
