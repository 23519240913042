<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'

import Summary from './summary'
import Patients from './patients'
import Users from './users'

import {
  userMethods,
  monitorMethods
} from '@/state/helpers'
import {getApi} from '@/api'

/**
 * Overview component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Summary,
    Patients,
    Users,
  },
  data() {
    return {
      title: 'Overview',
      items: [
        {
          text: 'VRsteps'
        },
        {
          text: 'Overview',
          active: true
        }
      ],
      flags: {
        isAdmin: false,
        renderUsers: false,
        renderPatients: false
      }
    }
  },
  methods: {
    ...userMethods,
    ...monitorMethods,
    ...getApi(),
    init() {
      let userId = this.$store.state.user.id
      let accessToken = this.$store.state.user.access_token

      console.log(accessToken, userId, this.$store.state.user.type)
      if (this.$store.state.user.type === 'admin') {
        this.getUsers(userId, accessToken).then(response => response.json()).then(
            (results) => {
              if (results.status) {
                this.setUsers({users: results.users})
                this.flags.renderUsers = true
              }
            }
        )
      }

      if (this.$store.state.user.type === 'therapist') {
        this.getPatients(userId, accessToken).then(response => response.json()).then(
            (results) => {
              this.setPatients({patients: results.patients})
              this.flags.renderPatients = true
            }
        )
      }

      this.isAdmin().then(flag => this.flags.isAdmin = flag)
    }
  },
  created() {
    console.log('created')
    this.init();
  },
  mounted() {
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <Summary/>
    <Patients v-if="flags.renderPatients"/>
    <Users v-if="flags.renderUsers"/>
  </Layout>
</template>
